import React from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

// components
import { MainNav } from 'src/components/navigation';
import { MainMenu } from 'src/sections/Navigation';
import { ConsentManager } from 'src/containers/ConsentManager';
import Footer from 'src/components/footer';
import FooterV3 from 'src/components-v3/Footer';
import { Banner, UnidentifiedTraffic } from 'src/components/Feedback';
import { Unless } from 'src/components/helpers';
import { MetaTags } from 'src/components/MetaTags';
import { MainNavigationBar } from 'src/components-v2/MainNavigationBar';

// helpers
import { getCurrentPage } from 'src/lib/utils';
import { AnalyticsProvider, LocaleContext } from 'src/contexts';
import { Locales, CmsThemes } from 'src/lib/constants';

// data
import { noJSBannerData } from 'src/store/banners';

// dynamically import the scripts component which invokes all of our js dependencies
const Scripts = dynamic(
  () => {
    return import('src/components/Scripts');
  },
  {
    ssr: false,
  },
);
const ForeignContentNotification = dynamic(
  () => {
    return import(
      'src/components/Feedback/ForeignContentNotification/ForeignContentNotification.tsx'
    );
  },
  {
    ssr: false,
  },
);

// For legacy static pages
const Default = ({
  children,
  locale = Locales.EN_US,
  cmsTheme = 'web 2.0',
  ...props
}) => {
  const { route, query } = useRouter();

  return (
    <LocaleContext.Provider value={locale}>
      <AnalyticsProvider>
        <MetaTags {...props} />
        <div className='banner-container'>
          <noscript>
            <Banner {...noJSBannerData} />
          </noscript>
        </div>

        {cmsTheme === CmsThemes.WEB3 ? (
          <MainNavigationBar />
        ) : locale === Locales.EN_GB || locale === Locales.EN_EU ? (
          <>
            <MainNav {...props} />
            <ForeignContentNotification />
          </>
        ) : (
          <MainMenu />
        )}

        <div
          id='main-content'
          className={`${getCurrentPage(route) || 'homepage'} default ${
            query?.index
          }`}
        >
          {children}
        </div>
        <Unless condition={props && props['exclude-footer']}>
          {cmsTheme === CmsThemes.WEB3 ? <FooterV3 /> : <Footer {...props} />}
        </Unless>
        <ConsentManager />
        <UnidentifiedTraffic />
        <Scripts route={route} {...props} />
      </AnalyticsProvider>
    </LocaleContext.Provider>
  );
};

export default Default;
